<template>
  <b-card>
    <div>
      <!-- User Info: Input Fields -->
      <validation-observer ref="formStaff">
        <b-form @submit.prevent="handleSubmit">
          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                ref="previewEl"
                :src="avatar"
                :text="avatarText(staff.fullName)"
                size="90px"
                rounded
              />
            </template>
            <h4 v-if="staff.fullName" class="mb-1">
              {{ staff.fullName }}
            </h4>
            <div class="d-flex flex-wrap">
              <b-button variant="primary" @click="$refs.refInputEl.click()">
                <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer" />
                <span class="d-none d-sm-inline">Upload ảnh</span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
              </b-button>
            </div>
          </b-media>

          <!-- Header: Personal Info -->

          <b-row class="mt-1">
            <!-- Field: Username -->
            <b-col cols="12" md="4">
              <b-form-group label="Tên đăng nhập" label-for="username">
                <b-form-input id="username" v-model="staff.username" disabled="" />
              </b-form-group>
            </b-col>

            <!-- Field: Username -->
            <b-col cols="12" md="4">
              <b-form-group label="Mật khẩu" label-for="password">
                <b-input-group>
                  <b-form-input
                    id="password"
                    v-model="staff.password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- Field: Full Name -->
            <b-col cols="12" md="4">
              <b-form-group label="Họ tên" label-for="full-name">
                <b-form-input id="full-name" v-model="staff.fullName" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="align-items-center">
            <!-- Field: Email -->
            <b-col cols="12" md="4">
              <b-form-group label="Số điện thoại" label-for="phoneNumber">
                <b-form-input id="phoneNumber" v-model="staff.phoneNumber" />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-row>
                <b-col>
                  <b-form-checkbox v-model="staff.isActive" class="custom-control-primary" switch>
                    Kích hoạt
                  </b-form-checkbox>
                </b-col>
                <b-col>
                  <b-form-checkbox
                    v-model="staff.isSuperAdmin"
                    class="custom-control-primary"
                    switch
                  >
                    Super Admin
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- Header: Personal Info -->
          <div class="d-flex mt-2">
            <h4 class="mb-0">Thông tin khác</h4>
          </div>
          <b-row class="mt-1">
            <!-- Field: Birth Date -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Ngày sinh" label-for="birth-date">
                <flat-pickr
                  v-model="staff.birthDay"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d' }"
                  placeholder="YYYY-MM-DD"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Email" label-for="email">
                <b-form-input id="email" v-model="staff.email" type="email" />
              </b-form-group>
            </b-col>

            <!-- Field: Gender -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Giới tính" label-for="gender" label-class="mb-1">
                <b-form-radio-group
                  id="gender"
                  v-model="staff.gender"
                  :options="genderOptions"
                  value="male"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Facebook" label-for="facebook">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon size="16" icon="FacebookIcon" />
                  </b-input-group-prepend>
                  <b-form-input id="facebook" v-model="staff.facebook" type="url" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Địa chỉ" label-for="address">
                <b-form-input id="address" v-model="staff.address" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2 justify-content-center">
            <!-- Action Buttons -->
            <b-button
              variant="outline-secondary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: constRouter.USER_STAFF.name }"
            >
              Hủy
            </b-button>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Sửa
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BFormRadioGroup,
  BCard,
  BAvatar,
  BMedia,
  BInputGroupAppend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { avatarText } from '@core/utils/filter';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import constRouter from '@/constants/constRouter';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import {
  // ValidationProvider,
  ValidationObserver,
} from 'vee-validate';
import { required } from '@validations';
import { edit, show } from '@/apis/apiStaff';

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadioGroup,
    BCard,
    BAvatar,
    BMedia,
    BInputGroupAppend,

    flatPickr,

    // ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const genderOptions = [
      { text: 'Nam', value: 'male' },
      { text: 'Nữ', value: 'female' },
    ];

    const avatar = ref(null);

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      // eslint-disable-next-line no-param-reassign
      avatar.value = base64;
    });

    return {
      avatar,
      avatarText,
      genderOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      constRouter,
    };
  },
  data: () => ({
    required,
    staff: {
      username: '',
      password: '',
      fullName: '',
      phoneNumber: '',
      isSuperAdmin: '',
      isActive: '',
      birthDay: '',
      email: '',
      gender: '',
      facebook: '',
      address: '',
      groupId: '',
    },
    id: '',
  }),
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchUser(this.id);
  },
  methods: {
    fetchUser(id) {
      show(id)
        .then((res) => {
          const { data } = res.data;
          if (data) {
            this.staff.username = data.username;
            this.staff.password = '';
            this.staff.fullName = data.fullName;
            this.staff.phoneNumber = data.phoneNumber;
            this.staff.isSuperAdmin = data.isSuperAdmin;
            this.staff.isActive = data.isActive;
            this.staff.birthDay = data.birthDay;
            this.staff.email = data.email;
            this.staff.gender = data.gender;
            this.staff.facebook = data.facebook;
            this.staff.address = data.address;
            this.staff.groupId = data.groupId;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSubmit() {
      this.$refs.formStaff.validate().then((success) => {
        if (success) {
          const { id, avatar } = this;

          // const formData = new FormData();
          // Object.keys(this.staff).forEach((key) => {
          //   if (
          //     !(this.staff[key] === undefined || this.staff[key] === null || this.staff[key] === '')
          //   ) {
          //     formData.append(key, this.staff[key]);
          //   }
          // });
          // formData.append('avatar', avatar);

          edit(id, this.staff)
            .then(() => {
              this.$router
                .push({ name: constRouter.USER_STAFF_DETAIL.name, params: { id } })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Sửa nhân viên',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      text: 'Sửa nhân viên thành công!',
                    },
                  });
                });
            })
            .catch((err) => {
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sửa nhân viên',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Sửa nhân viên thất bại!',
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
