import jwt from '@/auth/jwt/useJwt';

export function show(id) {
  return jwt.axiosIns.get(`staffs/${id}`);
}

export function remove(id) {
  return jwt.axiosIns.delete(`staffs/${id}`);
}

export function create(data) {
  return jwt.axiosIns.post('staffs', data);
}

export function edit(id, data) {
  return jwt.axiosIns.put(`staffs/${id}`, data);
}

export function editStatus(id, status) {
  return jwt.axiosIns.patch(`staffs/${id}`, { status });
}

export function list(pagination) {
  return jwt.axiosIns.get('staffs', {
    params: {
      page: 1,
      limit: 10,
      ...pagination,
    },
  });
}

export function updatePermissions(roleId, permissions) {
  return jwt.axiosIns.put(`/staffs/${roleId}/permissions`, { permissions });
}

export function updateRoles(staffId, roles) {
  return jwt.axiosIns.put(`/staffs/${staffId}/roles`, { roles });
}
